<template>
    <div class="space-y-4">
        <SelectLocation
            v-for="(location, index) in locations"
            :key="location.id"
            :location="location"
            :selected_location_ids="selected_location_ids"
            v-on:location_added="locationAdded"
            v-on:location_removed="locationRemoved"
        />
    </div>
</template>

<script>
import SelectLocation from '@glo/SelectLocation.vue'
export default {
    components: {
        SelectLocation
    },
    props: ['locations', 'selected_location_ids'],
    setup(props, { emit }) {


        const locationAdded = ({ location_id }) => {
            emit('location_added', { location_id })
        }

        const locationRemoved = ({ location_id }) => {
            emit('location_removed', { location_id })
        }

        return {
            locationAdded,
            locationRemoved
        }
    }
}
</script>