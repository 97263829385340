<template>
    <label class="group relative block cursor-pointer rounded-lg border bg-white pr-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between hover:border-blue-500 transition" :class="state.selected ? 'border-transparent border-blue-500 ring-1 ring-blue-500' : 'border-gray-300'">
        <input v-model="state.selected" type="checkbox" name="server-size" :value="location.id" class="sr-only" :aria-labelledby="`location-${location.id}-name`" :aria-describedby="`location-${location.id}-description`">
        <div class="flex-shrink-0 px-3 flex items-center justify-center">
            <span class="transition rounded-full h-6 w-6 flex items-center justify-center group-hover:border-blue-500" :class="state.selected ? 'bg-blue-500' : 'border border-2 border-gray-200'">
                <CheckIcon v-show="state.selected" class="text-white h-5 w-5" />
            </span>
        </div>
        <span class="flex-1 items-center">
            <span class="flex flex-col text-sm">
                <span :id="`location-${location.id}-name`" class="font-medium text-gray-900" v-html="location.name"></span>
                <span :id="`location-${location.id}-description`" class="text-gray-500">
                    <span class="block sm:inline" v-html="location.address_line_1"></span>
                    <span class="hidden sm:mx-1 sm:inline" aria-hidden="true">&middot;</span>
                    <span class="block sm:inline" v-html="location.town"></span>
                    <span class="hidden sm:mx-1 sm:inline" aria-hidden="true">&middot;</span>
                    <span class="block sm:inline" v-html="location.postcode"></span>
                </span>
            </span>
        </span>
        <span class="pointer-events-none absolute -inset-px rounded-lg border-1" :class="state.selected ? 'border border-blue-500' : 'border-1 border-transparent'" aria-hidden="true"></span>
    </label>
</template>
<script>
import { reactive, watch, onMounted } from 'vue'
import CheckIcon from "@ico/CheckIcon.vue"

export default {
    props: ['location', 'selected_location_ids'],
    components: {
        CheckIcon
    },
    setup(props, { emit }) {

        const state = reactive({
            selected: false
        })

        const setSelectedState = () => {
            state.selected = props.location?.id && props.selected_location_ids.includes(props.location.id)
        }

        const locationAdded = () => {
            if (!props.selected_location_ids.includes(props.location.id)) emit('location_added', { location_id: props.location.id })
        }

        const locationRemoved = () => {
            if (props.selected_location_ids.includes(props.location.id)) emit('location_removed', { location_id: props.location.id })
        }

        watch(
            () => props.selected_location_ids,
            () => {
                setSelectedState()
            }
        )

        watch(
            () => state.selected,
            () => {
                state.selected
                    ? locationAdded()
                    : locationRemoved()
            }
        )

        onMounted(() => {
            setSelectedState()
        })

        return{
            state
        }
    }
}
</script>